<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Организации</h1>
    <app-cells position="start">
      <router-link :to="{ name: 'org-create' }" class="btn btn--link btn--link-icon">
        <span>Добавить организацию</span>
      </router-link>
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <v-select
            :options="edu_org_options"
            :filterable="false"
            :clearable="true"
            @search="onEduOrgSearch"
            label="name"
            placeholder="Поиск учебной организации"
            class="select select--minw"
        >
          <template #open-indicator>
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </template>
          <template #option="option">
            <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
          </template>
          <template #selected-option="option">
            <div class="selected d-center">{{ option.name }}</div>
          </template>
          <template #no-options>Ничего не найдено</template>
        </v-select>
        <v-select
            :options="org_options"
            :filterable="false"
            :clearable="true"
            @search="onOrgSearch"
            label="name"
            placeholder="Поиск организации"
            class="select select--minw"
        >
          <template #open-indicator>
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </template>
          <template #option="option">
            <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
          </template>
          <template #selected-option="option">
            <div class="selected d-center">{{ option.name }}</div>
          </template>
          <template #no-options>Ничего не найдено</template>
        </v-select>
      </app-cells>
      <v-select
          @search="onFilterSearch"
          noDrop
          label="id"
          placeholder="Наименование / ИНН"
          class="select select--minw"
      />
    </app-cells>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default"
    >
      <div slot="value" slot-scope="props" class="table-default__left">
        {{ props.row.value }}
      </div>
      <div slot="address" slot-scope="props" class="table-default__left">
        {{ props.row.address }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3" ref="trigger">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
                fill="#2B93E7"/>
              <path
                d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
                fill="#2B93E7"/>
              <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link :to="{name: 'org-edit', params: {id: props.row.id}}" class="tooltip__btn">Редактировать</router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="onDeleteItem(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import {getCommonOrganizations, deleteCommonOrganization, getEduOrg, getOrg} from '@/http'

export default {
  name: 'OrgList',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'value', 'address', 'tooltip'],
        options: {
          headings: {
            id: 'ID',
            value: 'Наименование',
            address: 'Адрес',
            tooltip: '',
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      edu_org_options: [],
      org_options: [],
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(page = 1, page_size = 20, search = '') {
      getCommonOrganizations({ page, page_size, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onDeleteItem(id) {
      deleteCommonOrganization(id)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Организация удалена'
          })
          this.fetchItems()
        })
        .catch(error => {
          for (const key in error.response.data) {
            this.$notify({
              type: 'error',
              title: 'Внимание!',
              text: error.response.data[key][0]
            })
          }
        })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
    onEduOrgSearch(search, loading) {
      loading(true)
      this.searchEduOrg(loading, search, this)
    },
    searchEduOrg: debounce((loading, search, vm) => {
      getEduOrg(search)
        .then(response => {
          vm.edu_org_options = response.data
          loading(false)
        })
    }, 350),
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      getOrg(search)
        .then(response => {
          vm.org_options = response.data
          loading(false)
        })
    }, 350),
  }
}
</script>
